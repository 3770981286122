import * as React from 'react';
import * as styles from './about.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '../components/button';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { graphql } from 'gatsby';

const AboutPage = ({ data }) => {
  const headshotImages = data.headshotImages.edges;

  return (
    <>
      <SEO
        title="About Us"
        description="The leading quiz-building platform empowering businesses to engage audiences, boost conversions, and drive growth through interactive, personalized experiences."
      />
      <Header btnTheme="primary" />
      <div className={ styles.about }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">About Interact</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center">We are all about connecting brands and customers, at a human level.</h2>
              <Button theme="secondary" type="internal" href="/jobs/">Discover open positions</Button>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ padding: '0 0 5rem 0'}}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-9 text-center">
              <div className={ styles.frame }>
                <GatsbyImage image={ getImage(data.aboutImage) } alt="Interact team group picture"/>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" style={{ padding: '2rem 0 5rem 0' }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our Mission</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 text-center">
              <p className="heading-lg color-pink" style={{ margin: '1rem 0 3rem' }}>Our mission is to empower digital entrepreneurs, creators, and brands just like you to <strong>grow your business</strong> through empathetic listening, deeper understanding, and true connection.</p>
              <p className="heading-sm color-black" style={{ marginBottom: '3rem' }}>Interact was founded back in 2014 - well before quizzes were hot on social media - because we realized how difficult it was for online marketers to get to know their customers as individual human beings. We wanted to recreate that old school feeling of walking up to a storefront and having an actual conversation with the person running the business. And from that idea, Interact's quiz builder was born.</p>
              <p className="heading-sm color-black">We help businesses accelerate conversion rates at every stage of the sales funnel - whether you're building a quiz to generate more high-quality leads, make recommendations on which products or services will be best suited for your needs, or drive engagement with your community.</p>
            </div>
          </div>
        </Section>
        <Section theme="white" style={ { padding: `0 0 5rem 0` } }>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>Empowering businesses since 2014 with proven results:</p>
            </div>
          </div>
          <div className={ `row no-gutters align-items-center justify-content-center ${ styles.metrics }` }>
            <div className="col-md-4">
              <p className={ styles.number }>1.2B</p>
              <p className={ styles.label }>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={ styles.number }>400M</p>
              <p className={ styles.label }>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={ styles.number }>85M</p>
              <p className={ styles.label }>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="white" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our Philosophy</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-lg-6">
              <div className={ styles.feature } style={{ background: '#edf0ff' }}>
                <p className="heading-xs-upper">What we believe</p>
                <p className="heading-lg color-primary-950">Our success is dependent on your success.</p>
                <p className="heading-sm color-primary-950">For this reason, we take so much pride in supporting our community to become better marketers for their own businesses.</p>
                <p className="heading-sm color-primary-950">We do this by empathetically understanding our customers' needs, constantly optimizing our platform, and actively investing in providing ongoing support and training in best practices and strategy.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={ styles.feature }>
                <p className="heading-xs-upper">Our principles</p>
                <p className="heading-lg">At Interact, we:</p>
                <ul className="heading-sm" style={{ paddingLeft: '1.25rem', marginBottom: '0' }}>
                  <li>Put your interests ahead of company profit, trusting that what's best for the company will follow</li>
                  <li>Empower our team to be autonomous, make the right calls, and do their jobs well</li>
                  <li>Believe that great ideas come from everywhere regardless of position or status</li>
                  <li>Strive for our company and customers to reflect the diversity of our world</li>
                  <li>Take a “listen and understand first” approach to working together and with our customers</li>
                </ul>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our Identity</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-lg-6 order-1">
              <div className={ styles.feature } style={{ background: '#edf0ff' }}>
                <p className="heading-xs-upper">Who we are</p>
                <p className="heading-lg color-primary-950">Bootstrapped, remote, small, and mighty.</p>
                <p className="heading-sm color-primary-950">What kept us going during those incredibly tough early days of startup life were Chips Ahoy!, instant ramen, and the belief that we were building something that would only become more important as businesses increasingly went online.</p>
                <p className="heading-sm color-primary-950">Today with over 100,000 customers globally, we wake up every morning thankful to be able to help other entrepreneurs grow your businesses & tell your stories. We love what we do and are super excited to continue innovating for you.</p>
              </div>
            </div>
            <div className="col-lg-6 order-0">
              <div className={ styles.feature }>
                <p className="heading-xs-upper">Our works style</p>
                <p className="heading-lg">People who are successful here:</p>
                <ul className="heading-sm" style={{ paddingLeft: '1.25rem', marginBottom: '0' }}>
                  <li>Do their best work from anywhere</li>
                  <li>Don't let egos get in the way of great teamwork and collaboration</li>
                  <li>Are comfortable not getting it right the first time</li>
                  <li>Are students for life</li>
                  <li>Represent the world we live in</li>
                  <li>Are collaborators at the core</li>
                  <li>Are obsessed with solving the right problems</li>
                </ul>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white" style={ { padding: `2rem 0 5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our Culture</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-lg-6">
              <div className={ styles.feature } style={{ background: '#edf0ff' }}>
                <p className="heading-xs-upper">How we work</p>
                <p className="heading-lg color-primary-950">Healthy collaboration and creative autonomy.</p>
                <p className="heading-sm color-primary-950">We are nimble, focus on outcomes, and are experts in our areas. We enjoy collaborating and learning new things from each other all the time, and always, always, always, make decisions with our customers at the center of our "why".</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={ styles.feature }>
                <p className="heading-xs-upper">Core beliefs</p>
                <p className="heading-lg">People who are successful here:</p>
                <ul className="heading-sm" style={{ marginBottom: '0' }}>
                  <li>Take a customer-centric approach to everything</li>
                  <li>Work best autonomously and are not afraid to make the call</li>
                  <li>Celebrate each other's successes</li>
                  <li>Listen and understand first</li>
                  <li>Play zone defense (we work together to solve challenges, not in silos)</li>
                  <li>Are excited to pitch in when our teammates need help</li>
                </ul>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our Team</h3>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[1].node) } alt="Damaris Pacheco"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Damaris Pacheco</strong></p>
                <p className="text-body color-muted-foreground">Customer Success</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[7].node) } alt="Jesy Nelson"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Jesy Nelson</strong></p>
                <p className="text-body color-muted-foreground">Marketing Ops Manager</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[10].node) } alt="Matthew Clark"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Matthew Clark</strong></p>
                <p className="text-body color-muted-foreground">CTO, Co-Founder</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[0].node) } alt="Annie Tsai"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Annie Tsai</strong></p>
                <p className="text-body color-muted-foreground">COO</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[8].node) } alt="Josh Haynam"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Josh Haynam</strong></p>
                <p className="text-body color-muted-foreground">CEO, Co-Founder</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[11].node) } alt="Melanie Lew"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Melanie Lew</strong></p>
                <p className="text-body color-muted-foreground">Operations Specialist</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[2].node) } alt="Evan Nichols"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Evan Nichols</strong></p>
                <p className="text-body color-muted-foreground">Software Engineer</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[4].node) } alt="Jackie Aguglia"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Jackie Aguglia</strong></p>
                <p className="text-body color-muted-foreground">Partnerships</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[3].node) } alt="Graham Bartlett"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Graham Bartlett</strong></p>
                <p className="text-body color-muted-foreground">Software Engineer</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[6].node) } alt="Jessmyn Solana"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Jessmyn Solana</strong></p>
                <p className="text-body color-muted-foreground">Digital Marketing Manager</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[9].node) } alt="Mark Case"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Mark Case</strong></p>
                <p className="text-body color-muted-foreground">Business Coach</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[5].node) } alt="Jared Cooperstein"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Jared Cooperstein</strong></p>
                <p className="text-body color-muted-foreground">Jr. Engineer</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[12].node) } alt="Mia Fitzpatrick"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Mia Fitzpatrick</strong></p>
                <p className="text-body color-muted-foreground">Customer Success</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className={ styles.headshot }>
                <GatsbyImage image={ getImage(headshotImages[13].node) } alt="Philip Cressler"/>
                <p className="heading-sm color-black" style={{ marginTop: '1rem' }}><strong>Philip Cressler</strong></p>
                <p className="text-body color-muted-foreground">Software Engineer</p>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="white">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <p className="heading-xl text-center" style={{ marginBottom: '2rem' }}>Hello, from Interact's founders</p>
            </div>
            <div className="col-12 col-lg-8 text-center">
              <div className={ [styles.frame, styles.small].join(' ') }>
                <GatsbyImage image={ getImage(data.foundersImage) } alt="Interact co-founders, Josh Haynam and Matthew Clark"/>
              </div>
            </div>
            <div className="col-12 col-lg-8 text-center">
              <p className="heading-lg color-pink text-center" style={{ margin: '3rem 0' }}><strong>We started Interact because we saw a big gaping hole right at the heart of online marketing</strong></p>
              <p className="heading-sm color-black">Effective marketing is all about relationships. It's about truly connecting with your customers so you can help them find the perfect solutions for their problems.<br/><br/>Y'know? Make their lives better?<br/><br/>Let's face it, most marketing misses the mark when it comes to making people feel seen and understood. But that's not your fault. As an online business owner you hardly ever get to have a direct conversation with your clients or customers.<br/><br/>It might even feel like you're flinging your message out there into the void. And you have no idea if it's connecting with the right people.<br/><br/>In the “real world” you get to know your prospects personally. You can ask them questions about themselves. You develop true empathy towards their needs and aspirations. Which makes it way easier to actually help them reach their goals.<br/><br/>An Interact quiz helps you do the same thing.<br/><br/>And the connection, understanding, and personalized touch you create with your quiz will help you stand out in your industry - so you can attract more leads with less effort, increase your sales, and have a bigger impact.</p>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>
      </div>
      <Footer/>
    </>
  )
}

export default AboutPage;

export const query = graphql`
  query {
    headshotImages: allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "about/headshots"}}, sort: {fields: relativePath}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData (
              width: 260,
              height: 260,
              quality: 100
            )
          }
        }
      }
    },
    aboutImage: file(relativePath: { eq: "about/group.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 900,
          quality: 100,
        )
      }
    },
    foundersImage: file(relativePath: { eq: "about/founders.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 467,
          quality: 100,
        )
      }
    }
  }
`